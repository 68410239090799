<template>
    <div style="width:100vw;margin:-12px">
        <!-- app bar -->
        <top-nav title="Reporting"></top-nav>

        <div v-if="customers" class="pt-5">
            <v-text-field v-model="q" class="rounded-xl mb-3 mx-3 mt-2" flat solo background-color="grey lighten-3" label="Buscar" hide-details dense prepend-inner-icon="mdi-magnify" clearable></v-text-field>
            <div class="d-flex align-center mt-3 mx-3">
                <v-menu bottom left offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-chip v-bind="attrs" v-on="on" :close="report !== -1" @click:close="report = -1; customers = []; dates = []">
                            <v-icon v-if="report !== -1" v-text="reportIcon" small class="mr-2"></v-icon>
                            <span v-text="reportText"></span>
                        </v-chip>
                    </template>

                    <v-list>
                        <v-list-item v-for="(item, i) in reports" :key="`sort-item-${i}`" :disabled="report === item.value" @click="report = item.value" dense>
                            <v-list-item-icon class="mx-0">
                                <v-icon v-text="item.icon" small></v-icon>
                            </v-list-item-icon>
                            <v-list-item-title v-text="item.text"></v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>
            <div v-if="report !== -1" class="mt-7 mx-3">
                <v-menu ref="datesMenu" v-model="datesMenu" :close-on-content-click="false" :return-value.sync="dates" transition="scale-transition" offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="datesText" label="Desde - Hasta" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="dates" @change="$refs.datesMenu.save(dates); datesMenu = false; getReport()" range no-title scrollable>

                    </v-date-picker>
                </v-menu>
            </div>
            <div v-if="q && filteredCustomers.length === 0" class="d-flex justify-center align-center" style="width: 100%line-break:anywhere">
                <v-icon class="mr-2">mdi-alert-circle-outline</v-icon>
                <span>No se encontraron resultados para <strong>"{{ q }}"</strong></span>
            </div>
            <div v-else-if="q" class="d-flex justify-center align-center" style="width: 100%line-break:anywhere">
                <span>Mostrando {{ filteredCustomers.length }} {{ resultText }} para <strong>"{{ q }}"</strong></span>
            </div>
            <!-- content -->
            <v-list two-line v-if="filteredCustomers.length > 0 || loading">
                <v-list-item v-for="(customer, i) in filteredCustomers" :key="`customer-${i}`" :ripple="false">
                    <v-list-item-avatar>
                        <v-img v-if="customer.profilePic != null && customer.profilePic.key.length > 0" :src="customer.img"></v-img>
                        <v-avatar color="accent" size="36" class="white--text" v-else>{{ getCustomerInitials(customer) }}</v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title class="text-left">{{ getCustomerFullName(customer) }}</v-list-item-title>
                        <v-list-item-subtitle v-text="customer.email"></v-list-item-subtitle>
                        <v-list-item-subtitle>{{ getSubscriptionProductName(customer.subscriptions.items) }}</v-list-item-subtitle>
                        <v-list-item-subtitle>Próxima fecha expiración: {{ getSubscriptionNextPaymentDate(customer.subscriptions.items) }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                        {{ getTotalCheckIns(customer.checkIns.items) }}
                    </v-list-item-action>
                </v-list-item>
            </v-list>
            <div v-else-if="report === 0 && dates.length === 2" class="mx-3 d-flex align-center">
                <v-icon color="red darken-1" class="mr-3">mdi-alert</v-icon>
                No se encontraron check-ins para esas fechas
            </div>
            <v-progress-circular v-if="loading" style="position:absolute;bottom: 25px;left: 50%;transform: translateX(-50%)" :loading="loading" :size="50" color="primary" indeterminate ></v-progress-circular>
        </div>

        <v-skeleton-loader class="mx-auto" type="list-item-avatar-two-line, list-item-avatar-two-line, list-item-avatar-two-line, list-item-avatar-two-line" v-else></v-skeleton-loader>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import TopNav from '@/components/admin/TopNav'
import { PetuCustomer } from '@/classes/customer'

export default {
    name: 'Reporting',
    components: { TopNav },
    data:() => ({
        q: '', 
        loading: false,
        report: -1,
        height: false,
        isActive: false,
        isCheckins: false,
        customers: [],
        datesMenu: false,
        dates: [],
        reports: [
            { text: 'Check-ins', value: 0, icon: 'mdi-account-multiple-check' },
        ]
    }),
    methods: {
        ...mapActions({ getCustomers: 'customersInfo/getCustomersWithImages' }),
        async getReport() {
            switch (this.report) {
                case 0:
                    this.loading = true
                    this.customers = await this.getCustomers(this.q)
                    this.loading = false
                    break
                default: break
            }
        },
        getCustomerInitials(customer) {
            let c = new PetuCustomer(customer)
            return c.getInitials()
        },
        getCustomerFullName(customer) {
            let c = new PetuCustomer(customer)
            return c.getFullName()
        },
        getTotalCheckIns(checkIns) {
            return checkIns.filter((cI) => cI.checkinDate >= this.sortedDates[0] && cI.checkinDate <= this.sortedDates[1]).length
        },
        getSubscriptionProductName(subscriptions) {
            if (!subscriptions.length) {
                return 'N/A'
            }

            const s = subscriptions.reduce((p, c) => (new Date(p.nextPaymentDate) > new Date(c.nextPaymentDate)) ? p : c)
            return s.product?.name || 'N/A'
        },
        getSubscriptionNextPaymentDate(subscriptions) {
            if (!subscriptions.length) {
                return 'N/A'
            }
            const date = new Date(Math.max(...subscriptions.map(e => new Date(e.nextPaymentDate))))
            return date.toLocaleDateString()
        },
        filterByCheckInDate(c) {
            if (this.sortedDates.length !== 2) return false
            return !!c.checkIns.items.find((cI) => cI.checkinDate >= this.sortedDates[0] && cI.checkinDate <= this.sortedDates[1])
        },
    },
    computed: {
        ...mapGetters({ user: 'auth/user' }),
        sanitizedQ: ({ q }) => q?.toLowerCase()?.trim() || null,
        filteredCustomers() {
            let keys        = ['firstName', 'lastName', 'email']
            let customers   = this.report !== -1 ? this.customers.filter(this.filterByCheckInDate) : this.customers
            return !this.sanitizedQ ? customers : customers.filter((c) =>
                keys.some((k) => String(c[k]).toLowerCase().includes(this.sanitizedQ))
            )
        },
        datesText() {
            if (!this.dates.length) {
                return ''
            } else if (this.dates.length === 1) {
                return this.dates[0]
            } else {
                return this.sortedDates.join(' - ')
            }
        },
        resultText: ({ filteredCustomers }) => filteredCustomers.length === 1 ? 'resultado' : 'resultados',
        reportIcon() {
            return this.report === -1 ? '' : this.reports.find((i) => i.value === this.report).icon
        },
        reportText() {
            return this.report === -1 ? 'Reporte' : this.reports.find((i) => i.value === this.report).text
        },
        sortedDates() {
            return this.dates.sort((a, b) => new Date(a) - new Date(b))
        },
    },
}
</script>